import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';

import Seo from '../components/Seo';

const RecordingsPage = ({ data }) => {
  const title = 'Recordings';
  const slug = '/recordings';
  const description = 'A complete listing of JLP recordings.';

  return (
    <div
      data-aos="fade-in"
      data-aos-delay="150"
    >
      <h1 className="page-header">{title}</h1>
      <div className="page-content">
        <ul className="large-block-grid-6">
          {data.allPrismicAlbum.edges.map(({ node }) => (
            <li
              className="album"
              key={node.prismicId}
            >
              <Link
                to={`/content/recordings/${node.prismicId}/${node.slugs[0]}`}
              >
                {node.data.cover_art.thumbnails.Icon.fluid
                  && (
                    <Image
                      fluid={node.data.cover_art.thumbnails.Icon.fluid}
                    />
                  )
                }
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {data.allPrismicAlbum.edges.length === 0 && (
        <span className="results-msg">
          0 recordings found.
        </span>
      )}
      <Seo
        slug={slug}
        title={title}
        description={description}
      />
    </div>
  );
};

RecordingsPage.propTypes = {
  data: PropTypes.object,
};

export default RecordingsPage;

export const query = graphql`
  {
    allPrismicAlbum(
      sort: {
        fields: [
          data___release_date
        ],
        order: DESC
      }
    ) {
      edges {
        node {
          prismicId
          slugs
          data{
            title
            release_date
            description {
              html
              text
            }
            cover_art {
              thumbnails {
                Column {
                  url
                }
                Icon {
                  fluid(
                    maxWidth: 102
                  ) {
                    ...GatsbyPrismicImageFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
